@import './variables';
@import './mixins';


.flexGrid{display:flex;align-items:center;justify-content:center;}
.hscaWrap,.bgWrap{@include sec-default();}
.fullSec,.kpGrid{@include sec-default();
  // .flexWrap{display:flex;align-items:center;justify-content:space-around;}
  .hlf,.hlf1,.hlf2,.col,.left,.right{display:inline-block;vertical-align:top;}
  .hlf1,.col1,.left{float:left;}
  .hlf2,.col3,.right{float:right;}

  .hlf{width:48%;}
  .grid25{width:25%;}
  .grid30{width:30%;}
  .grid33{width:33%;}
  .grid35{width:35%;}
  .grid40{width:40%;}
  .grid42{width:42%;}
  .grid43{width:43%;}
  .grid45{width:45%;}
  .grid48{width:48%;}
  .grid50{width:50%;}
  .grid52{width:52%;}
  .grid55{width:55%;}
  .grid56{width:56%;}
  .grid57{width:57%;}
  .grid60{width:60%;}
  .grid65{width:65%;}
  .grid67{width:67%;}
  .grid25, .qtr{width:25%;}

  .size{width:auto;margin-left:20px;margin-right:20px;}

  .left.pull{margin-left:-3%;}
  .right.pull{margin-right:-3%;}
  .left.push{margin-left:3%;}
  .right.push{margin-right:3%;}
  .mid3{margin-left:5.5%;}

  // @include bp(br){
  //   .medDef.mediaStack{max-width:680px;}
  //   .medDef.mediaStack .left, .medDef.mediaStack .right{width:100% !important;}
  // }
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw, .insideTw, .custWrap{@include center-block();width:90%;}
.inside, .insideAlt, .insideXl, .insideN{max-width:1600px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}
// @include bp(tb){.medDef, .med2Def, .med3Def, .med4Def, .insideNrw, .insideTw, .custWrap{width:92%;}}

.clearfix::after{content:"";clear:both;display:table;}
.textCenter{text-align:center;}

p,h5,h4,h3,h2,h1,span{color:inherit;font-family:inherit;
  &.bold{font-weight:700;}
  &.semi{font-weight:500;}
  &.bmargin0{margin-bottom:0;}
  &.bmargin5{margin-bottom:5px;}
  &.tmargin0{margin-top:0;}
}
p{line-height:1.5em;margin-top:0;font-weight:300;}
// a:not(.shLink){color:$green;}
a{text-decoration:none;color:$green;
  &:hover{text-decoration:none;color:inherit;}
}
ul{padding-left:0;list-style-type:none;margin-top:auto;}
img{max-width:100%;}

.linkRow{@include sec-default();margin-top:10px;}
.shLink, .shLinkLg, button.shLink{background-color:$green;color:$white;text-align:center;line-height:1em;text-transform:uppercase;letter-spacing:1px;font-weight:600;box-shadow:0px 10px 30px rgba($black,.15);font-family:$body;
  &:hover{text-decoration:none;@include ease(.3s);opacity:.85;color:$white;}
  &.white{background-color:$white;color:$green;}
  
}
button.shLink{border:inherit;}//font-weight:600;font-family:$body;}
.shLink, button.shLink{font-size:15px;padding:7px 20px;}
.shLinkLg, button.shLinkLg{font-size:16px;padding:10px 30px;}
.ilLink, button.ilLink{display:inline-block;margin-left:10px;margin-right:10px;}//border:2px solid $green;}
button:hover{cursor:pointer;}
button.nostyle{background-color:transparent;color:inherit;font-family:$body;padding:0;border:0;font-weight:inherit;font-size:inherit;}