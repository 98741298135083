@import '../../theme/global';

.Gallery{text-align:center;padding:50px 0;position:relative;z-index:0;margin-top:40px;
  // &::before{@include before();height:400px;background-color:$cream;opacity:.8;}//top:-10%;transform:skewY(12deg);}
  @include bp(nn){padding:30px 0;}


  .galleryHead{margin-bottom:50px;color:$green;
    h1{font-size:32px;font-family:$heading;line-height:1.4em;}
    p{font-size:21px;}

    a, button{position:relative;z-index:0;padding-bottom:4px;
      &::after{@include after();height:2px;left:0;background-color:$greenB;opacity:.2;}
      &:hover::after{opacity:1;}
    }

    @include bp(tw){
      .med3Def{max-width:700px;}
      h1{font-size:27px;margin-bottom:15px;}
      p{font-size:19px;}
    }

    @include bp(nn){margin-bottom:20px;
      .med3Def{max-width:600px;}
      h1{font-size:24px;}
      p{font-size:17px;}
    }
    @include bp(og){
      .med3Def{max-width:500px;}
      h1{font-size:21px;}
    }
    @include bp(oc){
      h1{font-size:20px;max-width:370px;@include center-block();}
    }
  }


  .optNav{margin-bottom:30px;padding-bottom:15px;border-bottom:1px solid $black01;
    li{display:inline-block;margin:5px 20px;}
  }
  .galleryUL{
    p{font-weight:500;margin-top:5px;}

    @include bp(tw){p{font-size:17px;}}
    @include bp(nn){p{font-size:16px;}}
    
    @include bp(oc){
      li{@include sec-default();}
    }
  }
  @include bp(oc){.gallerySec .inside{max-width:350px;}}
}