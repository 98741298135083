@import '../../theme/global';


.Thanks{padding:220px 0 50px;text-align:center;
  h1{font-size:34px;line-height:1.4em;}
  p{font-size:25px;max-width:800px;@include center-block();}
  a{position:relative;z-index:0;padding-bottom:4px;color:$greenB;font-weight:500;
    &::after{@include after();height:2px;left:0;background-color:$greenB;opacity:.2;}
    &:hover::after{opacity:1;}
  }

  @include bp(nn){padding-top:150px;
    .medDef,.med2Def{max-width:700px;}
    h1{font-size:28px;}
    p{font-size:20px;}
  }
  @include bp(og){padding-top:60px;
    .medDef,.med2Def{max-width:550px;}
    h1{font-size:22px;}
    p{font-size:18px;}
  }

  @include bp(oc){padding-top:30px;
    h1{font-size:21px;}
    p{font-size:17px;}
  }
}