$white:#fff;
$black:#000;
$black01:rgba($black, .1);
$f8:#f8f8f8;

$green:#2d5016;
$greenLt:#DBE8D3;
$greenB:#4f8c27;
$brown1:#784421;
$brown2:#c1852b;
$cream:#F8F2E9;
$cream2:#FCF9F6;
$bg:$cream2;

$mul: 'Mulish', sans-serif;
$dm: 'DM Serif Display', serif;
$heading: $dm;
$body:$mul;

