@import '../../theme/global.scss';

.Social{display:inline-block;
  &:not(.inherit){
    li{display:inline-block;margin:0 5px;
      &:first-child{margin-left:0;}
      &:last-child{margin-right:0;}
    }
  }

  // &.intro svg{fill:$white;}
  // &.footer li{width:16px;}
  // @include bp(tb){&.header li{width:16px;}}
  // @include bp(xs){&.header li{width:15px;}}
}