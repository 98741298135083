@import '../../theme/global';


/*******************************/
/*********** HEADER ***********/
/*******************************/
.headerSec{position:relative;z-index:3;
  .headContact{background-color:rgba($greenB,.1);padding:4px 0 7px;text-align:right;
    ul{margin:0;}
    .ilHd{display:inline-block;margin-left:25px;}
    a{color:$green;font-size:15px;}
    svg{width:16px;}
  }

  .headWrap{padding-top:5px;}
  .headLogo{max-width:200px;float:left;}
  .navSide{float:right; margin:55px 0 0;}
  .navItem{display:inline-block;margin-right:30px;font-weight:500;font-size:17px;
    &:last-child{margin-right:0;}
    svg{width:18px;margin-top:-2px;}
  }
  button{background-color:transparent;color:$green;font-family:$body;padding:0;border:0;font-weight:500;font-size:inherit;}

  .mobNav{display:none;position:absolute;right:0;top:48px;
    button{width:30px;padding:5px 3px;border:1px solid $green;}
    span{@include sec-default();height:2px;background-color:$green;}
    span:nth-child(2){margin:3px 0;}
  }

  @include bp(nn){.inside{width:94%;}}
  @include bp(og){
    .headLogo{max-width:170px;}
    .navItem{margin-right:20px;}
  }
  @include bp(oc){
    .headContact{text-align:center;
      .ilHd{margin:0 7px;}
    }
    .navRow{position:relative;max-width:300px;}
    .headLogo{max-width:150px;@include center-block();}
    .navSide{display:none;}
    .mobNav{display:block;}
  }
}

.SideNavList{padding:60px 30px 0;width:98%;max-width:350px;
  .closeIcon{position:absolute;right:12px;top:7px;cursor:pointer;font-weight:bold;color:$green;}

  .menu li{@include sec-default();line-height:1em;margin-bottom:20px;
    a, button{font-weight:500;text-transform:none;font-size:17px;line-height:1em;color:$green;}
    button{background-color:transparent;border:0;padding:0;}
  }
  p{font-size:17px;}

  @include bp(sm){padding-left:20px;padding-right:20px;}
}