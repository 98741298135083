@import '../../theme/global';


/*******************************/
/*********** FOOTER ***********/
/*******************************/
.footWrap{background-color:darken($green,6%);padding:10px 0;position:relative;z-index:0;color:$white;text-align:center;
  a{color:$cream;}
  
  .footCopy{
    p{display:inline-block;margin:0 4px;font-size:14px;}
  }
  @media (min-width:640px){
    p:first-child{float:left;}
    p:last-child{float:right;}
  }
  @include bp(oc){
    .insideAlt{width:92%;}
    .footCopy p{line-height:1.2em;}
  }
}