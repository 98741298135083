@import '../../theme/global';

// background: linear-gradient(to top left, cyan 70%, palegoldenrod 30%);
.Home{
  .fullSec{
    &.topSkew, &.btmSkew{position:relative;z-index:1;}
    &.topSkew::before{@include before();transform:skewY(8deg);}
    &.btmSkew::before{@include after();left:0;transform:skewY(8deg);}

    &.skCream::before{background-color:$cream;}
    &.skWhite::before{background-color:$white;}
  }

  .simpleIntro{padding:100px 0 150px;text-align:center;
    h1{font-size:45px;font-family:$heading;margin-bottom:5px;}
    h2{font-size:21px;color:$greenB;text-transform:uppercase;letter-spacing:2px;margin-top:0;}
    h3{font-size:22px;font-weight:300;margin-top:0;line-height:1.4em;}

    @include bp(nn){padding:70px 0 100px;
      .med4Def{max-width:500px;}
      h1{font-size:33px;}
      h2{letter-spacing:1px;}
      h2, h3{font-size:19px;}
      .shLink{font-size:14px;}
    }
    @include bp(og){
      h1{font-size:30px;}
      h2{letter-spacing:0px;}
      h2, h3{font-size:17px;}
      h3{margin-bottom:10px;}
      .shLink{padding:10px 25px;margin:10px 6px 0;font-size:13px;}
    }
    @include bp(oc){padding:40px 0 70px;
      h1{font-size:24px;max-width:380px;@include center-block();
        br{display:none;}
      }
      h2, h3{font-size:16px;}
    }
  }

  .banner{
    .medDef{width:95%;max-width:1100px;}
    .pullup{position:relative;z-index:1;padding-top:10px;display:flex;align-items:center;justify-content:space-between;
      &::before{@include abs();width:116%;height:100%;top:-35px;left:-8%;background-color:$white;border-top-left-radius:80px;border-top-right-radius:80px;}
    }
    
    h3,p{margin:0;}
    h3{font-size:29px;font-family:$heading;}
    p{font-size:20px;line-height:1.7em;}
    .shLink{font-size:14px;}
    .mobLink{display:none;}

    @media (max-width:1300px){
      .pullup{padding-top:0;}
      .pullup::before{width:110%;left:-5%;top:-45px;}
    }
    @include bp(tw){
      .pullup{justify-content:space-evenly;}
      .pullup::before{width:100%;left:0;}
      h3{font-size:25px;}
      p{font-size:18px;}
    }
    @include bp(nn){
      .pullup::before{top:-30px;}
      h3{font-size:24px;}
      p{font-size:17px;}
      p{margin-bottom:10px;}
      .mobLink{display:block;}
      .col3{display:none;}
    }
    @include bp(og){padding-top:20px;
      .medDef{max-width:550px;}
      .pullup{flex-direction:column;justify-content:center;text-align:center;}
      .pullup::before{display:none;}
      h3{font-size:22px;}
      p{margin-bottom:10px;}
      .mobLink{display:block;}
      .col3{display:none;}
      .shLink{font-size:13px;}
    }
    @include bp(fi){p br{display:none;}}
  }

  .plaxIntro{margin-top:-140px;background-image:url('../../images/Marrakesh1.jpg');min-height:600px;background-size:cover; 
    @media (min-width:1800px){min-height:800px;margin-top:-180px;}
    @media (max-width:1300px){min-height:550px;}
    @include bp(tw){min-height:480px;}
    @include bp(nn){min-height:400px;}
    @include bp(og){min-height:300px;margin-top:-60px;}
    @include bp(fi){min-height:250px;}
  }


  .serveStackC{margin-top:60px;padding:100px 0;position:relative;z-index:1;
    .text{position:relative;z-index:0;
      &::before{@include abs();width:170px;height:170px;top:-15%;left:-10%;border-radius:50%;background-color:$cream;opacity:.6;}
    }
    .imageMob, .textMob, .rowMob{display:none;}
    .boxTitle{
      p{font-size:19px;margin-bottom:15px;line-height:1.4em;
        span.main{font-size:23px;font-weight:800;} //font-family:$heading;}
        span.stretch{font-style:italic;}
      }
    }
    p.body span{color:$greenB;font-weight:600;}

    .ssWrap1{max-width:1100px;}
    .col1a{width:35%;
      .image{width:95%;float:right;}
      .text{width:115%;float:left;margin-left:-40px;margin-top:100px;}
    }
    .col2a{width:60%;float:right;
      .text{width:85%;max-width:500px;margin-left:10%;margin-top:60px;}
      .image{width:100%;float:right;margin-right:-60px;margin-top:60px;}
    }

    .row2{margin-top:80px;}
    .col1b{width:59%;margin-left:-1%;}
    .col2b{width:45%;float:right;margin-right:-12%;margin-top:10%;}
    
    
    @media(min-width:1500px){
      .ssWrap1{max-width:1300px;}
      .col2b{margin-right:-16%;}
    }
    @media(min-width:1590px){.col1a .text{margin-left:-80px;}}

    @media(max-width:1300px){padding-bottom:50px;margin-top:20px;
      .boxTitle p{font-size:18px;
        span.main{font-size:22px;}
      }

      .ssWrap1{max-width:950px;}
      .col1a{width:38%;
        .image{width:100%;}
        .text{width:95%;float:right;margin-left:0;}
      }
      .col2a{width:58%;
        .image{margin-right:-30px;}
      }

      .row2{margin-top:0px;}
      .col1b{width:52%;margin-left:-2%;margin-top:70px;}
      .col2b{max-width:390px;margin-right:0;margin-top:-40px;}
    }

    @include bp(nn){padding:80px 0 30px;margin-top:0;
      .text::before{width:100px;height:100px;top:-10%;left:-7%;}
      .boxTitle p, p.body{font-size:17px;}
      .boxTitle p span.main{font-size:20px;}
      
      .ssWrap1{max-width:700px;}
      .col1a,.col2a{@include sec-default();
        .text,.image{display:inline-block;}
      }
      .col1a{
        .image{width:34%;float:left;}
        .text{width:58%;margin-top:35px;}
      }
      .col2a{
        .text{width:47%;margin-left:0;}
        .image{width:48%;margin-right:0;margin-top:0px;}
      }

      .col1b{width:47%;margin-left:0;margin-top:50px;}
      .col2b{width:45%;max-width:100%;margin-top:-40px;margin-right:0;}
    }

    @include bp(og){padding-top:30px;
      .text::before{width:100px;height:100px;top:-30px;left:-30px;}
      .boxTitle p, p.body{font-size:17px;}
      .boxTitle p span.main{font-size:20px;}
      
      .ssWrap1,.ssWrap2{max-width:500px;}
      .image{display:none !important;}
      .text{width:100% !important;margin:0 !important;max-width:100% !important;}

      .col2a{margin:15px 0 25px;
        .text{text-align:right;}
        .text::before{left:auto;right:-20px;}
      }
      .col2b{width:100%;margin-top:0px;}


      .rowMob{display:block;text-align:center;margin-top:20px;}
      .imageMob{display:inline-block;
        img{max-height:220px;border:2px solid $white;}
      }
    }

    @include bp(mb){
      .imageMob img{max-height:200px;}
    }

    @include bp(oc){
      .boxTitle p span.main{font-size:19px;}
      .imageMob:first-child{display:none;}
      .imageMob:last-child{width:90%;@include center-block();
        img{max-height:100%;width:100%;}
      }
    }
  }

  .plax1{padding:270px 0 200px;color:$white;position:relative;z-index:0;background:$green;
    &::after{@include before();height:250px;top:-150px;background-color:$white;transform:skewY(5deg);}

    h3{font-size:30px;letter-spacing:1px;margin-bottom:60px; text-align:center;font-family:$heading;}
    p{font-size:19px;margin-bottom:0;}
    p+p{margin-top:30px;}
    a{color:$white;}


    .content{position:relative;z-index:0;
      &::before{@include before();width:49.7%;border-right:3px solid rgba($white,.6);}
    }
    .left{padding-right:15px;}
    .right{padding-left:15px;}
    .big{@include sec-default();margin-top:20px;
      p{font-size:24px;font-weight:bold;margin-bottom:10px;}
      a, button{font-size:19px;position:relative;z-index:0;padding-bottom:4px;
        &::after{@include after();height:2px;left:0;background-color:$white;opacity:.2;}
        &:hover::after{opacity:1;}
      }
      button{margin-left:20px;}
    }

    @include bp(tx){padding:230px 0 140px;}
    @include bp(tw){padding:200px 0 110px;background-size:cover;
      &::after{height:200px;top:-110px;}
      h3{margin-bottom:40px;}
      .big{
        p{font-size:22px;}
        a, button{font-size:18px;}
      }
    }
    @include bp(nn){padding:180px 0 100px;
      p{font-size:18px;}
      .big p{font-size:20px;}
    }

    @include bp(og){padding:120px 0 40px;
      &::after{height:100px;top:-50px;}
     
      .medDef{max-width:500px;}
      h3{font-size:24px;letter-spacing:0px;margin-bottom:25px;}
      .hlf{width:100%;}
      .right{margin-top:20px;}
      p{font-size:17px;}

      .left,.right{padding:0;}
      .content::before{display:none;}
      .big{margin-top:20px;}
      .big{
        p{font-size:18px;}
        a, button{font-size:16px;}
      }
    }

    @include bp(fi){padding:100px 0 35px;
      .medDef{width:88%;}
      h3{font-size:20px;margin-bottom:15px;}
    }
  }

  .insta{padding:80px 0 120px;text-align:center;
    p{font-size:34px;line-height:1.2em;margin-bottom:0;font-family:$heading;}
    a{font-size:25px;font-weight:bold;}

    .instaWidge{width:100%;border:0;overflow:hidden;}
    .feed{margin-top:50px;}

    @include bp(tw){padding:50px 0 100px;}
    @include bp(nn){padding:30px 0 70px;
      p{font-size:30px;}
      a{font-size:22px;}
      .feed{margin-top:30px;}
    }
    @include bp(og){padding:20px 0 35px;
      p{font-size:25px;}
      a{font-size:19px;}
      .feed{margin-top:15px;}
    }
    @include bp(xx){
      p{font-size:20px;}
      a{font-size:17px;}
    }
  }
}