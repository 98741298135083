@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,500;0,600;0,800;1,500&family=DM+Serif+Display&display=swap');
@import './theme/global';


*, *::before, *::after{box-sizing:border-box;}
body{margin:0;font-size:18px;font-family:$body;color:$black;}
.shcaWrap{position:relative;}
.mainWrap{@include sec-default();
  @media (min-width:768px){min-height:90vh;}
  @include bp(og){min-height:85vh;}
}

iframe{width:100%;border:none;min-width:100%;max-width:100%;}// height:539px;
.quoteModal{
  .closeBtn{position:absolute;right:15px;top:15px;color:$black;z-index:1012;}
  .titleSec{text-align:center;margin-top:20px;width:90%;@include center-block();}
  h4{font-weight:bold;margin-bottom:4px;}
  p{font-size:17px;}

  @include bp(oc){
    h4{font-size:18px;}
    p{font-size:16px;}
  }
}

.ToTopBtn{right:10px !important;background-color:$green !important;border-radius:50%;border:1px solid $white;text-align:center;
  svg{margin-bottom:6px;fill:$white !important;width:18px;height:18px;}
}